// Plugin CSS
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";

// Init main SCSS File
@import "/public/assets/scss/style.scss";
.whatsapp_float {
    position: fixed;
          width: 50px;
          height: 50px;
          bottom: 35px;
          right: 7%;
          background-color: #25d366;
          color: #FFF;
          border-radius: 50px;
          text-align: center;
          font-size: 30px;
          box-shadow: 2px 2px 3px #999;
          z-index: 100;
}